<template>
  <div>
    <v-list v-if="memberList.length">
      <v-list-item
        v-for="member in memberList"
        :key="member.id"
      >
        <router-link :to="{ name: 'SocialProfile', params: { username: member.profile.user }}">
          <v-list-item-avatar>
            <v-img :src="member.profile.avatar_thumbnail_url" />
          </v-list-item-avatar>
        </router-link>
        <v-list-item-content>
          <v-row align="center">
            <v-col>
              <v-list-item-title v-text="member.profile.user" />
              <div
                v-if="hasEvidence && !member.presence_confirmed"
                class="points-label mt-2"
              >
                The organizer must confirm this member's presence in order for them to be eligible for points.
              </div>
            </v-col>
            <v-col
              v-if="hasEvidence"
              md="3"
              class="text-right"
            >
              <div v-if="member.presence_confirmed">
                Confirmed
              </div>
              <div v-else>
                <v-btn
                  v-if="allowConfirm"
                  :disabled="submitting"
                  @click="confirmPresence(member.profile)"
                >
                  Confirm Presence
                </v-btn>
                <div v-else>
                  Unconfirmed
                </div>
              </div>
            </v-col>
          </v-row>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-snackbar
      v-model="snackbar"
      timeout="3000"
    >
      {{ snackbarText }}
    </v-snackbar>
  </div>
</template>

<script>
import { allianceAPI } from '@/api'
import { mapState } from 'vuex'

export default {
  props: {
    memberList: {
      type: Array,
      required: true,
    },
    participantId: {
      type: Number,
      required: true,
    },
    allowConfirm: {
      type: Boolean,
      default: true,
    },
    hasEvidence: {
      type: Boolean,
      default: false,
    },
  },
  data: function() {
    return {
      submitting: false,
      snackbar: false,
      snackbarText: null,
    }
  },
  computed: {
    ...mapState({
      profile: state => state.auth.profile,
      userCoordinates: state => state.userlocation.coordinates,
    }),
  },
  methods: {
    async confirmPresence(profile) {
      if (!this.$online) {
        this.snackbarText = 'An internet connection is required to confirm presence.'
        return this.snackbar = true
      }
      this.submitting = true
      const data = new FormData()
      data.append('profile', profile.id)
      // Get user location.
      await this.$store.dispatch('userlocation/get')
      if (this.userCoordinates) {
        data.append('latitude', this.userCoordinates.lat)
        data.append('longitude', this.userCoordinates.lng)
      }
      // Submit.
      return allianceAPI.patch('/quest/participant/' + this.participantId + '/present/', data)
        .then((response) => {
          this.$store.dispatch('participants/replace', {replacementObject: response.data})
        })
        .catch(() => {
          this.snackbarText = 'Failed to confirm presence.'
          this.snackbar = true
        })
        .finally(() => this.submitting = false)
    },
  },
}
</script>
