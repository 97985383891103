<template>
  <div v-if="participant && quest">
    <v-alert
      v-for="alert in alerts"
      :key="alert.content"
      :type="alert.type"
    >
      {{ alert.message }}
    </v-alert>

    <v-dialog
      v-model="successDialog"
      max-width="400"
    >
      <v-card>
        <v-card-title class="text-center">
          <v-col class="text-center pa-0">
            Quest {{ successDialogType }}
          </v-col>
        </v-card-title>
        <v-card-text>
          <!-- eslint-disable vue/singleline-html-element-content-newline -->
          <span v-if="successDialogType == 'Saved' || successDialogType == 'Joined'">
            <div class="text-center mb-4">
              <router-link :to="{name: 'Logbook'}">
                <v-icon
                  size="200"
                  color="primary"
                >
                  $logbook
                </v-icon>
              </router-link>
            </div>
            The Quest {{ quest.name }} has been saved to your <router-link :to="{name: 'Logbook'}">Logbook</router-link><span v-if="successDialogType == 'Joined'"> and started</span>.
          </span>
          <span v-else-if="successDialogType == 'Started'">
            The Quest {{ quest.name }} has been activated.
          </span>
          <span v-else-if="successDialogType == 'Quit'">
            You have quit the Quest {{ quest.name }}.
          </span>
          <span v-else-if="successDialogType == 'Resume'">
            You have resumed the Quest {{ quest.name }}.
          </span>
          <!--eslint-enable-->
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            @click="successDialog = false"
          >
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="offlineDialog"
      max-width="400"
    >
      <v-card>
        <v-card-title>
          Internet Connection Required
        </v-card-title>
        <v-card-text>
          An internet connection is required<span v-if="offlineAction"> to {{ offlineAction }} this Quest</span>.
        </v-card-text>
      </v-card>
    </v-dialog>

    <Loading
      v-if="loading"
      :loading="loading"
      message="Loading Quest"
    />

    <div v-if="!loading && quest">
      <QuestHeader
        :quest="quest"
        :details="questDetails"
        :participant="participant"
      />

      <v-row>
        <v-col>
          <Share
            :title="quest.name"
            class="mr-4"
          />
          <Like
            :object-id="quest.id"
            app="quest"
            model="quest"
            class="mr-4"
          />
          <Flag
            :object-id="quest.id"
            app="quest"
            model="quest"
          />
        </v-col>
      </v-row>

      <div class="quest-meta mb-6">
        <v-divider v-if="participant" />
        <v-row v-if="participant">
          <v-col>
            Status
          </v-col>
          <v-col class="text-right">
            {{ participant.status_display }}
          </v-col>
        </v-row>
        <v-divider v-if="quest.author" />
        <v-row v-if="quest.author">
          <v-col>
            Author
          </v-col>
          <v-col class="text-right">
            <router-link :to="{ name: 'Author', params: { slug: quest.author.slug }}">
              {{ quest.author.name }}
            </router-link>
          </v-col>
        </v-row>
        <v-divider v-if="quest.story" />
        <v-row v-if="quest.story">
          <v-col>
            Story
          </v-col>
          <v-col class="text-right">
            <router-link :to="{ name: 'Story', params: { slug: quest.story.slug }}">
              {{ quest.story.name }}
            </router-link>
          </v-col>
        </v-row>
        <v-divider v-if="quest.season" />
        <v-row v-if="quest.season">
          <v-col>
            Season
          </v-col>
          <v-col class="text-right">
            <router-link :to="{ name: 'Season', params: { slug: quest.season.slug }}">
              {{ quest.season.name }}
            </router-link>
          </v-col>
        </v-row>
        <v-divider />
        <v-row>
          <v-col>
            Date
          </v-col>
          <v-col class="text-right">
            {{ quest.created|formatDate }}
          </v-col>
        </v-row>
        <v-divider v-if="quest.skills.length" />
        <v-row v-if="quest.skills.length">
          <v-col>
            Skills
          </v-col>
          <v-col class="text-right">
            <span
              v-for="(skill, index) in quest.skills"
              :key="skill.id"
            >
              {{ skill.name }}<span v-if="index != (quest.skills.length - 1)">, </span>
            </span>
          </v-col>
        </v-row>
        <v-divider />
      </div>

      <QuestLocked
        :quest="quest"
        :locked="questLocked"
      />

      <v-card
        v-if="!questLocked && quest.arena_geo"
        class="mt-6 mb-6"
        @click.stop="mapDialog = true"
      >
        <v-img
          v-if="quest.arena_static_url"
          :src="quest.arena_static_url"
          max-height="200"
        />
        <v-img
          v-else
          src="@/assets/default_map.jpg"
          max-height="200"
        />
        <v-card-title>
          View Map
        </v-card-title>
      </v-card>

      <v-dialog
        v-model="mapDialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-toolbar>
            <v-btn
              icon
              dark
              @click="mapDialog = false"
            >
              <v-icon>
                $close
              </v-icon>
            </v-btn>
            <v-toolbar-title>
              {{ quest.name }}: Arena Map
            </v-toolbar-title>
          </v-toolbar>
          <QuestMap
            :quest="quest"
            :visible="mapDialog"
          />
        </v-card>
      </v-dialog>

      <div
        v-if="!questLocked"
        class="section"
      >
        <div class="section-title">
          Dispatch
        </div>
        <!-- eslint-disable vue/no-v-html -->
        <div v-html="quest.dispatch" />
        <!--eslint-enable-->
      </div>

      <div v-if="participant">
        <div
          v-if="participant.participantobjective_set.length"
          class="section"
        >
          <div class="section-title">
            Objectives
          </div>
          <div class="section-content">
            <ParticipantObjectiveList
              :participant-objective-list="participant.participantobjective_set"
            />
          </div>
        </div>
        <div
          v-if="memberList.length || canInvite"
          class="section"
        >
          <div class="section-title">
            Roll Call
          </div>
          <div class="section-content">
            <ParticipantRollcall
              :member-list="memberList"
              :allow-confirm="isOrganizer"
              :participant-id="participant.id"
              :has-evidence="hasEvidence"
            />
            <div
              v-if="canInvite"
              class="text-center mt-4"
            >
              <v-btn
                align="center"
                :disabled="disableInvite"
                @click.stop="inviteDialog = true"
              >
                Invite Members
              </v-btn>
              <div
                v-if="isPartyFull"
                class="text-center points-label mt-4"
              >
                This party is full. You may not invite new members.
              </div>
            </div>
            <v-dialog
              v-model="inviteDialog"
              fullscreen
              hide-overlay
              transition="dialog-bottom-transition"
            >
              <v-card>
                <v-toolbar>
                  <v-btn
                    icon
                    dark
                    @click="inviteDialog = false"
                  >
                    <v-icon>
                      $close
                    </v-icon>
                  </v-btn>
                  <v-toolbar-title>
                    {{ quest.name }}: Selection
                  </v-toolbar-title>
                </v-toolbar>
                <ParticipantInvite
                  v-if="canInvite"
                  :participant="participant"
                  :visible="inviteDialog"
                />
              </v-card>
            </v-dialog>
          </div>
        </div>
        <div
          v-if="isStarted"
          class="section"
        >
          <div class="section-title">
            Evidence
          </div>
          <div class="section-content">
            <UploadEvidence
              v-if="canUploadEvidence"
              :participant="participant"
            />
            <ParticipantEvidenceList
              :participant-evidence-list="participant.participantevidence_set"
              :allow-delete="true"
              :show-status="true"
            />
          </div>
        </div>
      </div>

      <div class="text-center mb-4">
        <v-btn
          v-if="canStartQuest"
          :disabled="submitting"
          @click="startQuest"
        >
          Start Quest
        </v-btn>
      </div>
      <div class="text-center mb-4">
        <v-btn
          v-if="canUploadEvidence"
          color="primary"
          :disabled="submitting || !canCompleteQuest"
          @click="completeQuest"
        >
          Complete Quest
        </v-btn>
      </div>
      <div class="text-center mb-4">
        <v-btn
          v-if="isQuestStarted"
          :disabled="submitting"
          @click="quitQuest"
        >
          Quit Quest
        </v-btn>
      </div>
      <div class="text-center mb-4">
        <v-btn
          v-if="canResumeQuest"
          :disabled="submitting"
          @click="resumeQuest"
        >
          Resume Quest
        </v-btn>
      </div>

      <v-alert
        v-for="error in errorMessages"
        :key="error"
        type="error"
      >
        {{ error }}
      </v-alert>

      <v-dialog
        v-model="completeDialog"
        :fullscreen="$vuetify.breakpoint.smAndDown"
        :max-width="$vuetify.breakpoint.thresholds.sm"
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-toolbar>
            <v-btn
              icon
              dark
              @click="completeDialog = false"
            >
              <v-icon>
                $close
              </v-icon>
            </v-btn>
            <v-toolbar-title
              class="text-center mr-12"
              style="width:100%"
            >
              Review Submission
            </v-toolbar-title>
          </v-toolbar>
          <QuestSubmit
            :quest="quest"
            :participant="participant"
            :member-list="memberList"
          />
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import Flag from '@/components/Flag.vue'
import Like from '@/components/Like.vue'
import Loading from '@/components/Loading.vue'
import ParticipantEvidenceList from '@/components/ParticipantEvidenceList.vue'
import ParticipantInvite from '@/components/ParticipantInvite.vue'
import ParticipantObjectiveList from '@/components/ParticipantObjectiveList.vue'
import ParticipantRollcall from '@/components/ParticipantRollcall.vue'
import QuestHeader from '@/components/QuestHeader.vue'
import QuestLocked from '@/components/QuestLocked.vue'
import QuestMap from '@/components/QuestMap.vue'
import QuestSubmit from '@/components/QuestSubmit.vue'
import Share from '@/components/Share.vue'
import UploadEvidence from '@/components/UploadEvidence.vue'
import { allianceAPI } from '@/api'
import { parseResponseErrors } from '@/utils/errors'
import { mapState } from 'vuex'

export default {
  components: {
    Flag,
    Like,
    Loading,
    ParticipantEvidenceList,
    ParticipantObjectiveList,
    ParticipantRollcall,
    ParticipantInvite,
    QuestHeader,
    QuestLocked,
    QuestMap,
    QuestSubmit,
    Share,
    UploadEvidence,
  },
  data: function() {
    return {
      alerts: [],
      completeDialog: false,
      errorMessages: null,
      loading: false,
      mapDialog: false,
      inviteDialog: false,
      offlineAction: null,
      offlineDialog: false,
      questDetails: null,
      questLocked: null,
      startNow: false,
      submitting: false,
      successDialog: false,
      successDialogType: null,
    }
  },
  computed: {
    ...mapState({
      profile: state => state.auth.profile,
    }),
    memberList() {
      if (this.participant.participantprofile_set.length > 1) {
        return this.participant.participantprofile_set
      }
      return []
    },
    isOrganizer() {
      return this.participant.profile == this.profile.id
    },
    hasEvidence() {
      return this.participant.participantevidence_set.length > 0
    },
    isPartyFull() {
      return this.part_count >= this.quest.max_party_members
    },
    canInvite() {
      if (this.quest.max_party_members < 2) {
        return false
      }
      if (!this.profile.allow_quest_invites) {
        return false
      }
      if (!this.isOrganizer) {
        return false
      }
      if (this.hasEvidence) {
        return false
      }
      return true
    },
    disableInvite() {
      if (!this.$online) {
        return true
      }
      if (this.isPartyFull) {
        return true
      }
      return false
    },
    isQuestStarted() {
      return this.participant.status === 'active' || this.participant.status === 'rejected'
    },
    isStarted() {
      return this.participant.status !== 'saved'
    },
    canStartQuest() {
      return this.quest.can_accept_activations === true && this.participant.status === 'saved'
    },
    canResumeQuest() {
      return this.quest.can_accept_activations === true && this.participant.status === 'failed'
    },
    canCompleteQuest() {
      return this.participant.accept_submission
    },
    canUploadEvidence() {
      return this.$online && this.participant.accept_evidence
    },
    participant() {
      return this.$store.getters['participants/getByID'](this.$route.params.id)
    },
    quest() {
      return this.$store.getters['quests/getByID'](this.participant.quest.id)
    },
  },
  created: function () {
    this.getQuestDetails()
  },
  methods: {
    getQuestDetails() {
      return this.$store.dispatch('quests/getDetails', '/quest/quest/' + this.quest.id + '/details/')
        .finally(() => {
          this.questDetails = this.$store.getters['quests/getDetailsByID'](this.quest.id) || null
          this.loading = false
          if (!this.$online && !this.questDetails) {
            this.alerts.push({
              type: 'error',
              message: 'An internet connection is required to download the details of this Quest.',
            })
          } else if (!this.questDetails) {
            this.alerts.push({
              type: 'error',
              message: 'Failed to download Quest details.',
            })
          }
        })
    },
    startQuest() {
      if (!this.$online) {
        this.offlineAction = 'start'
        return this.offlineDialog = true
      }
      this.submitting = true
      this.errorMessages = null
      return allianceAPI.patch('/quest/participant/' + this.participant.id + '/begin/')
        .then(() => {
          this.$store.dispatch('participants/getAll')
          this.successDialogType = 'Started'
          this.successDialog = true
        })
        .catch((error) => {
          this.errorMessages = parseResponseErrors(error)
          this.success = false
        })
        .finally(() => this.submitting = false)
    },
    quitQuest() {
      if (!this.$online) {
        this.offlineAction = 'quit'
        return this.offlineDialog = true
      }
      this.submitting = true
      this.errorMessages = null
      return allianceAPI.patch('/quest/participant/' + this.participant.id + '/quit/')
        .then(() => {
          this.$store.dispatch('participants/getAll')
          this.successDialogType = 'Quit'
          this.successDialog = true
        })
        .catch((error) => {
          this.errorMessages = parseResponseErrors(error)
          this.success = false
        })
        .finally(() => this.submitting = false)
    },
    resumeQuest() {
      if (!this.$online) {
        this.offlineAction = 'resume'
        return this.offlineDialog = true
      }
      this.submitting = true
      this.errorMessages = null
      return allianceAPI.patch('/quest/participant/' + this.participant.id + '/resume/')
        .then(() => {
          this.$store.dispatch('participants/getAll')
          this.successDialogType = 'Resume'
          this.successDialog = true
        })
        .catch((error) => {
          this.errorMessages = parseResponseErrors(error)
          this.success = false
        })
        .finally(() => this.submitting = false)
    },
    completeQuest() {
      if (!this.$online) {
        this.offlineAction = 'complete'
        return this.offlineDialog = true
      }
      // Refresh participants prior to opening the complete dialog so that
      // we're dealing with the latest data.
      this.$store.dispatch('participants/getAll')
      this.completeDialog = true
    },
  },
}
</script>
