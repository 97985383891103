<template>
  <div v-if="participantEvidenceList.length">
    <v-simple-table>
      <thead>
        <tr>
          <th>
            Image
          </th>
          <th>
            Memo
          </th>
          <th v-if="showStatus">
            Status
          </th>
          <th v-if="allowDelete" />
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="evidence in participantEvidenceList"
          :key="evidence.id"
        >
          <td>
            <img
              v-if="evidence.image_thumbnail"
              :src="evidence.image_thumbnail"
              width="200"
            >
            <span v-else-if="evidence.image">Image Processing</span>
            <span v-else>No Image</span>
          </td>
          <td>
            {{ evidence.memo }}
          </td>
          <td v-if="showStatus">
            {{ evidence.get_status_display }}
          </td>
          <td
            v-if="allowDelete"
            class="text-right"
          >
            <v-btn
              v-if="evidence.can_delete && $online"
              icon
              :disabled="submitting"
              @click="deleteEvidence(evidence)"
            >
              <v-icon>
                $delete
              </v-icon>
            </v-btn>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <v-snackbar
      v-model="snackbar"
      timeout="3000"
    >
      {{ snackbarText }}
    </v-snackbar>
  </div>
  <div v-else>
    <p>You have not uploaded any evidence.</p>
    <p>Upload evidence to complete this Quest.</p>
  </div>
</template>

<script>
import { allianceAPI } from '@/api'

export default {
  props: {
    participantEvidenceList: {
      type: Array,
      required: true,
    },
    allowDelete: {
      type: Boolean,
      default: true,
    },
    showStatus: {
      type: Boolean,
      default: true,
    },
  },
  data: function() {
    return {
      submitting: false,
      snackbar: null,
      snackbarText: null,
    }
  },
  methods: {
    deleteEvidence(evidence) {
      this.submitting = true
      this.snackbarText = ''
      this.snackbar = false
      return allianceAPI.delete(`/quest/evidence/${evidence.id}/`)
        .then(() => {
          this.$store.dispatch('participants/getAll')
        })
        .catch(() => {
          this.snackbarText = 'Failed to delete evidence.'
          this.snackbar = true
        })
        .finally(() => {
          this.submitting = false
        })
    },
  },
}
</script>
