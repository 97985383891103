<template>
  <v-card
    v-if="event && event.attendees.length"
    :flat="flat"
  >
    <v-card-title>
      Attendees
    </v-card-title>
    <v-list>
      <v-list-item
        v-for="attendee in event.attendees"
        :key="attendee.id"
        :to="{ name: 'SocialProfile', params: { username: attendee.profile.user }}"
      >
        <v-list-item-avatar>
          <v-img :src="attendee.profile.avatar_thumbnail_url" />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-row align="center">
            <v-col>
              <v-list-item-title v-text="attendee.profile.user" />
            </v-col>
            <v-col class="text-right">
              <span v-if="attendee.profile.user == event.organized_by.user">
                Organizer
              </span>
              <span v-else>
                {{ attendee.status }}
              </span>
            </v-col>
          </v-row>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>

export default {
  props: {
    event: {
      type: Object,
      required: true,
    },
    flat: {
      type: Boolean,
      required: false,
    },
  },
}
</script>
