<template>
  <div
    class="objective"
    :class="{'objective-required':(participantObjective.quest_objective.is_required == true), 'objective-complete':(participantObjective.is_complete == true)}"
  >
    <v-row>
      <div class="is-complete">
        <slot name="check" />
      </div>
      <v-col>
        <div class="headline">
          {{ participantObjective.quest_objective.objective_type.name }}
          <span
            v-if="participantObjective.quest_objective.is_required"
            class="is-required"
          >
            *
          </span>
        </div>
      </v-col>
      <v-col class="text-right">
        <div>
          <div class="headline points">
            {{ participantObjective.points }}
          </div>
          <div class="points-label">
            points
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <div class="complete-spacer" />
      <v-col>
        <p>
          {{ participantObjective.quest_objective.instructions }}
        </p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    participantObjective: {
      type: Object,
      required: true,
    }
  },
}
</script>
