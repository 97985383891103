<template>
  <div v-if="quest.arena_geo && $online">
    <div id="map-container">
      <VueMapboxMap
        v-if="map"
        :map="map"
        :interactive="true"
        :lng="scene.lng"
        :lat="scene.lat"
        :zoom="scene.zoom"
        :pitch="scene.pitch"
        :bearing="scene.bearing"
        :data-visible="visible"
      />
    </div>
  </div>
  <div v-else-if="!$online">
    <v-alert type="info">
      Internet access is required for full mapping.
    </v-alert>
    <v-img
      v-if="quest.arena_static_url"
      :src="quest.arena_static_url"
      max-width="100%"
      max-height="100%"
    />
  </div>
  <div v-else>
    No map available.
  </div>
</template>

<script>
import VueMapboxMap from '@benchmark-urbanism/vue-mapbox-map'
import bbox from '@turf/bbox'
import mapboxgl from "mapbox-gl";

export default {
  components: {
    VueMapboxMap
  },
  props: {
    quest: {
      type: Object,
      required: true,
    },
    visible: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      map: null,
      bounds: null,
    }
  },
  computed: {
    scene() {
      return {
        zoom: 7,
        pitch: 0,
        bearing: 0,
        lng: this.quest.arena_center_longitude || -104.823574,
        lat: this.quest.arena_center_latitude || 39.707895,
      }
    },
  },
  updated: function () {
    if (this.map && this.visible) {
      this.sizeMap()
    }
  },
  mounted: function () {
    mapboxgl.accessToken = this.$mapboxAccessToken
    this.map = new mapboxgl.Map({
      container: 'map-container',
      style: this.$mapboxStyle,
    })
    this.map.on('load', () => {
      this.setMap()
    })
  },
  methods: {
    sizeMap () {
      this.map.resize()
      if (this.bounds) {
        this.map.fitBounds(this.bounds, {padding: 100});
      }
    },
    setMap () {
      this.map.addSource('Arena', {
        'type': 'geojson',
        'data': this.quest.arena_geo,
      })
      this.map.addLayer({
        'id': 'arena',
        'type': 'fill',
        'source': 'Arena',
        'layout': {},
        'paint': {
          'fill-color': '#f0f0f0',
          'fill-opacity': 0.3,
        },
      })
      this.bounds = bbox(this.quest.arena_geo);
      this.sizeMap()
    }
  },
}
</script>

<style>
#map-container {
  position: absolute;
  height: 100%;
  width: 100%;
}
@import url("https://api.mapbox.com/mapbox-gl-js/v2.3.1/mapbox-gl.css");
</style>
