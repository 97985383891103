<template>
  <v-row>
    <v-col
      cols="12"
      :lg="event && event.attendees.length ? 6 : 12"
    >
      <v-card flat>
        <v-form
          ref="form"
          @submit.prevent="submit"
        >
          <v-card-text>
            <v-alert
              v-if="success"
              type="success"
            >
              Your invitation was successfully sent.
            </v-alert>
            <v-alert
              v-for="error in errorMessages"
              :key="error"
              type="error"
            >
              {{ error }}
            </v-alert>
            <ProfileMultiSelect
              v-model="usernameList"
              :disabled="submitting"
            />
            <TeamMultiSelect
              v-if="profile.allow_teams"
              v-model="teamnameList"
              :disabled="submitting"
              hint="Optionally invite all members from a team."
            />
            <v-menu
              v-model="dateMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="startAt"
                  label="Event Date"
                  append-icon="$calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="startAt"
                :min="minDate"
                @input="dateMenu = false"
              />
            </v-menu>
            <v-textarea
              v-model="description"
              label="Message"
              auto-grow
              :disabled="submitting"
              :rules="eventDescriptionRules"
            />
          </v-card-text>
          <v-card-actions>
            <v-btn
              class="primary"
              type="submit"
              :disabled="submitting"
            >
              Send Invite
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-col>
    <v-col
      v-if="event"
      cols="12"
      lg="6"
    >
      <EventAttendeeList
        :event="event"
        :flat="true"
      />
    </v-col>
  </v-row>
</template>

<script>
import EventAttendeeList from '@/components/EventAttendeeList.vue'
import ProfileMultiSelect from '@/components/ProfileMultiSelect.vue'
import TeamMultiSelect from '@/components/TeamMultiSelect.vue'
import moment from 'moment'
import { allianceAPI } from '@/api'
import { eventDescriptionRules } from '@/utils/rules'
import { mapState } from 'vuex'
import { parseResponseErrors } from '@/utils/errors'

export default {
  components: {
    ProfileMultiSelect,
    TeamMultiSelect,
    EventAttendeeList,
  },
  props: {
    participant: {
      type: Object,
      required: true,
    },
    visible: {
      type: Boolean,
      required: true,
    },
  },
  data: function() {
    return {
      event: null,
      errorLoading: null,
      loading: null,
      success: null,
      usernameList: [],
      teamnameList: [],
      errorMessages: null,
      description: '',
      dateMenu: null,
      startAt: null,
      submitting: false,
      eventDescriptionRules: eventDescriptionRules,
      minDate: moment().format('YYYY-MM-DD'),
    }
  },
  computed: {
    ...mapState({
      profile: state => state.auth.profile,
    }),
    eventUsernames() {
      const usernames = []
      if (this.event) {
        for (let i = 0; i < this.event.attendees.length; i++) {
          usernames.push(this.event.attendees[i].profile.user)
        }
      }
      return usernames
    },
  },
  watch: {
    visible: function (value) {
      if (value) {
        this.getEvent()
      }
    },
  },
  mounted: function () {
    this.getEvent()
  },
  methods: {
    getEvent() {
      if (this.participant.event) {
        this.loading = true
        this.errorLoading = null
        allianceAPI.get(`/quest/event/${this.participant.event}`)
          .then((response) => {
            this.event = response.data
            this.initEvent()
          })
          .catch(() => this.errorLoading = true)
          .finally(() => this.loading = false)
      }
    },
    initEvent() {
      if (this.event) {
        this.description = this.event.description
        this.startAt = moment(this.event.start_at).format('YYYY-MM-DD')
      }
    },
    submit () {
      if (!this.$refs.form.validate()) {
        return
      }
      this.submitting = true
      this.success = null
      const data = new FormData()
      data.append('start_at', this.startAt)
      data.append('description', this.description)
      for (let i = 0; i < this.usernameList.length; i++) {
        data.append('invitees', this.usernameList[i]);
      }
      for (let i = 0; i < this.teamnameList.length; i++) {
        data.append('teams', this.teamnameList[i]);
      }
      return allianceAPI.post(`/quest/participant/${this.participant.id}/event/create/`, data)
        .then((response) => {
          this.success = true
          this.errorMessages = null
          this.event = response.data
          this.$store.dispatch('participants/updateObject', this.participant.id)
        })
        .catch((error) => {
          this.errorMessages = parseResponseErrors(error)
          this.success = false
        })
        .finally(() => this.submitting = false)
    },
  },
}
</script>
