<template>
  <div>
    <div class="help-text is-required">
      * Required Objective(s)
    </div>
    <ParticipantObjectiveSingle
      v-for="participantObjective in participantObjectiveList"
      :key="participantObjective.id"
      :participant-objective="participantObjective"
    >
      <template v-slot:check>
        <v-icon
          v-if="participantObjective.is_complete || checkAll"
          color="primary"
          large
        >
          $check
        </v-icon>
      </template>
    </ParticipantObjectiveSingle>
    <div v-if="showTotal">
      <v-divider />
      <div class="objective">
        <v-row>
          <div class="is-complete" />
          <v-col>
            <div class="headline">
              Total Points
            </div>
          </v-col>
          <v-col class="text-right">
            <div>
              <div class="headline points">
                {{ totalPoints }}
              </div>
              <div class="points-label">
                points
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import ParticipantObjectiveSingle from '@/components/ParticipantObjectiveSingle.vue'

export default {
  components: {
    ParticipantObjectiveSingle,
  },
  props: {
    participantObjectiveList: {
      type: Array,
      required: true,
    },
    showTotal: {
      type: Boolean,
      required: false,
    },
    checkAll: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    totalPoints() {
      return this.participantObjectiveList.reduce((prev, curr) => {
        return prev + curr.points
      }, 0)
    },
  },
}
</script>
