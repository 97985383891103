<template>
  <v-card>
    <v-img
      :src="(quest.image) ? quest.image : ''"
      height="200px"
      class="px-3"
    >
      <v-row>
        <v-col>
          <div class="headline">
            {{ quest.name }}
          </div>
          <div class="title">
            {{ quest.region.name }}
          </div>
          <div>
            <v-icon
              v-if="quest.quest_type.name == 'Chain'"
              small
            >
              $chain
            </v-icon>
            <v-icon
              v-else-if="quest.quest_type.name == 'Terra'"
              small
            >
              $terra
            </v-icon>
            <span v-else>
              {{ quest.quest_type.name }}
            </span>
          </div>
        </v-col>
        <v-col
          cols="auto"
          style="text-align: right;"
        >
          <v-icon v-if="participant">
            $logbook
          </v-icon>
        </v-col>
      </v-row>
      <v-row
        v-if="details"
        class="align-end"
      >
        <v-col>
          <div class="title">
            {{ details.num_active_participants }}
          </div>
          <div>
            Participants
          </div>
        </v-col>
        <v-col align="center">
          <div class="title">
            {{ details.num_completions }}
          </div>
          <div>
            Completions
          </div>
        </v-col>
        <v-col align="right">
          <div class="title">
            {{ details.num_likes }}
          </div>
          <div>
            Likes
          </div>
        </v-col>
      </v-row>
    </v-img>
  </v-card>
</template>

<script>

export default {
  props: {
    quest: {
      type: Object,
      required: true,
    },
    details: {
      type: Object,
      required: false,
      default: null,
    },
    participant: {
      type: Object,
      required: false,
      default: null,
    },
  },
}
</script>
