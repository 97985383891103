<template>
  <v-card
    v-if="locked === true || quest.can_accept_activations === false"
    class="not-compatible"
  >
    <v-card-title class="text-center">
      <v-col class="text-center pa-0">
        Quest Locked
      </v-col>
    </v-card-title>
    <v-card-text>
      <div class="text-center mb-4">
        <v-icon x-large>
          $lock
        </v-icon>
      </div>
      <div
        v-if="locked === true"
        class="prereq text-center"
      >
        You do not meet the prerequisites for {{ quest.name }}. This Quest is only available to those who have:
        <ul>
          <li v-if="quest.num_completed_quests">
            completed {{ quest.num_completed_quests }} quests
          </li>
          <li v-if="quest.prereq_min_points">
            earned {{ quest.prereq_min_points }} points
          </li>
          <li v-if="quest.prereq_completed_quests.length">
            completed
            <span
              v-for="(prereqQuest, index) in quest.prereq_completed_quests"
              :key="prereqQuest.id"
            >
              <router-link :to="{ name: 'Quest', params: { slug: prereqQuest.slug }}">
                {{ prereqQuest.name }}
              </router-link>
              <span v-if="index != (quest.prereq_completed_quests.length - 1)">, </span>
            </span>
          </li>
        </ul>
      </div>
      <div
        v-else-if="quest.can_accept_activations === false"
        class="prereq text-center"
      >
        This Quest is not currently accepting activations.
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    quest: {
      type: Object,
      required: true,
    },
    locked: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
}
</script>
