<template>
  <div>
    <v-card
      v-if="success"
      flat
      align="center"
    >
      <v-card-text>
        <v-icon
          size="200"
          color="primary"
        >
          $check
        </v-icon>
        <p class="text-h4">
          Quest Submitted
        </p>
        <p>
          Your submission will be reviewed and points will be awarded upon approval.
        </p>
        <p>
          You can review the status of your submission in your Logbook.
        </p>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn
          color="primary"
          :to="{ name: 'Logbook' }"
        >
          View Logbook
        </v-btn>
      </v-card-actions>
    </v-card>
    <div v-else>
      <QuestHeader
        :quest="quest"
        :details="null"
        :participant="participant"
      />
      <v-card-text>
        <div
          v-if="memberList.length"
          class="section"
        >
          <div class="section-title">
            Roll Call
          </div>
          <div class="section-content">
            <ParticipantRollcall
              :member-list="memberList"
              :allow-confirm="false"
              :participant-id="participant.id"
              :has-evidence="true"
            />
          </div>
        </div>
        <div class="section">
          <div class="section-title">
            Evidence
          </div>
          <div class="section-content">
            <ParticipantEvidenceList
              :participant-evidence-list="pendingEvidence"
              :allow-delete="false"
              :show-status="false"
            />
          </div>
        </div>
        <div class="section">
          <div class="section-title">
            Objectives
          </div>
          <div class="section-content">
            <ParticipantObjectiveList
              :participant-objective-list="requestedObjectives"
              :show-total="true"
              :check-all="true"
            />
          </div>
        </div>
        <div class="description">
          <p>
            Your submission will be reviewed. Points will be awarded upon approval.
          </p>
        </div>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn
          v-if="canSubmit"
          color="primary"
          :disabled="submitting"
          @click="submitQuest"
        >
          Submit Quest
        </v-btn>
        <v-alert
          v-else
          type="error"
        >
          You must upload evidence for at least one required objective before submitting your Quest.
        </v-alert>
      </v-card-actions>
    </div>
  </div>
</template>

<script>
import ParticipantEvidenceList from '@/components/ParticipantEvidenceList.vue'
import ParticipantObjectiveList from '@/components/ParticipantObjectiveList.vue'
import ParticipantRollcall from '@/components/ParticipantRollcall.vue'
import QuestHeader from '@/components/QuestHeader.vue'
import flatMap from 'lodash.flatmap'
import { allianceAPI } from '@/api'
import { parseResponseErrors } from '@/utils/errors'

export default {
  components: {
    ParticipantEvidenceList,
    ParticipantObjectiveList,
    ParticipantRollcall,
    QuestHeader,
  },
  props: {
    quest: {
      type: Object,
      required: true,
    },
    participant: {
      type: Object,
      required: true,
    },
    memberList: {
      type: Array,
      required: true,
    },
  },
  data: function() {
    return {
      success: null,
      submitting: false,
      errorMessages: null,
    }
  },
  computed: {
    requestedObjectives() {
      let requestedObjectiveIDs = flatMap(this.participant.participantevidence_set, 'requested_objectives')
      return this.participant.participantobjective_set.filter(function(x) {
        return requestedObjectiveIDs.indexOf(x.quest_objective.id) >= 0
      })
    },
    canSubmit() {
      return this.requestedObjectives.filter(x => x.quest_objective.is_required).length > 0
    },
    pendingEvidence() {
      return this.participant.participantevidence_set.filter(function(x) {
        return x.status === 'pending'
      })
    },
  },
  methods: {
    submitQuest() {
      this.submitting = true
      this.errorMessages = null
      return allianceAPI.patch('/quest/participant/' + this.participant.id + '/complete/')
        .then((response) => {
          console.log('success!')
          console.log(response)
          this.success = true
          this.$store.dispatch('participants/getAll')
        })
        .catch((error) => {
          this.errorMessages = parseResponseErrors(error)
          this.success = false
        })
        .finally(() => this.submitting = false)
    },
  },
}
</script>
