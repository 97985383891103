<template>
  <v-dialog
    v-model="evidenceDialog"
    :fullscreen="$vuetify.breakpoint.smAndDown"
    :max-width="$vuetify.breakpoint.thresholds.sm"
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <div class="text-center mb-4">
        <v-btn
          color="primary"
          v-bind="attrs"
          v-on="on"
        >
          Upload Evidence
        </v-btn>
      </div>
    </template>
    <v-card>
      <v-toolbar>
        <v-btn
          icon
          dark
          @click="closeDialog"
        >
          <v-icon>
            $close
          </v-icon>
        </v-btn>
        <v-toolbar-title
          class="text-center mr-12"
          style="width:100%"
        >
          Upload Evidence
        </v-toolbar-title>
      </v-toolbar>
      <v-form
        class="submit"
        @submit.prevent="submit"
      >
        <v-card-text>
          <Loading
            v-if="submitting"
            :loading="submitting"
            message="Submitting Evidence"
          />
          <div v-else>
            <v-alert
              v-for="error in errorMessages"
              :key="error"
              type="error"
            >
              {{ error }}
            </v-alert>
            <div class="description">
              <p>
                Select an image and/or enter a text memo as evidence of objective completion. You may create any number of evidence submissions.
              </p>
            </div>
            <div
              class="image-dropbox"
              :class="{'image-selected':(image),'input-error':(imageError)}"
            >
              <input
                id="image"
                class="image-input"
                type="file"
                accept="image/heic,image/heif,image/jpeg,image/png,.heic,.heif,.jpeg,.jpg,.png"
                :disabled="submitting"
                @change="imageInput($event)"
              >
              <div
                v-if="!image"
                class="text-center"
              >
                <v-icon
                  size="100"
                  color="primary"
                >
                  $plus
                </v-icon>
                <p class="description">
                  Select an image.
                </p>
              </div>
              <div
                v-if="imagePreview"
                align="center"
                class="imagePreview"
              >
                <v-btn
                  icon
                  class="clearInput"
                  @click="clearImage"
                >
                  <v-icon>
                    $close
                  </v-icon>
                </v-btn>
                <v-img
                  :src="imagePreview.src"
                  height="300"
                  contain
                />
              </div>
            </div>
            <div
              v-if="imageError"
              class="hint error-message"
            >
              {{ imageError }}
            </div>
            <div
              v-else
              class="hint"
            >
              <span v-if="imageLabel">{{ imageLabel }}</span>
              <span v-else>Images should be JPG, PNG, HEIC, or HEIF and no larger than 13MB.</span>
            </div>
            <v-textarea
              v-model="memo"
              label="Memo"
              auto-grow
              :disabled="submitting"
              :rules="memoRules"
            />
            <div
              v-if="evidenceInput"
              class="section"
            >
              <div class="section-title">
                Objectives
              </div>
              <div class="section-content">
                <div class="description">
                  <p>
                    Select all objectives that your evidence applies to. Your evidence will be reviewed and confirmed upon submission.
                  </p>
                </div>
                <ParticipantObjectiveSingle
                  v-for="participantObjective in objectiveList"
                  :key="participantObjective.id"
                  :participant-objective="participantObjective"
                >
                  <template v-slot:check>
                    <v-checkbox
                      v-model="requestedObjectives"
                      :value="participantObjective.quest_objective.id"
                    />
                  </template>
                </ParticipantObjectiveSingle>
              </div>
            </div>
          </div>
        </v-card-text>
        <v-card-actions
          v-if="!submitting"
          class="justify-center"
        >
          <v-btn
            class="primary"
            type="submit"
            :disabled="!evidenceInput || imageError || submitting"
          >
            Upload
          </v-btn>
        </v-card-actions>
      </v-form>
      <v-snackbar
        v-model="success"
      >
        Evidence uploaded successfully.
        <v-btn
          color="pink"
          text
          @click="success = null"
        >
          Close
        </v-btn>
      </v-snackbar>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import { allianceAPI } from '@/api'
import { checkImageValue, imageTimeout } from '@/utils/images'
import { parseResponseErrors } from '@/utils/errors'
import { formatBytes } from '@/utils/format'
import ParticipantObjectiveSingle from '@/components/ParticipantObjectiveSingle.vue'
import Loading from '@/components/Loading.vue'

export default {
  name: 'UploadEvidence',
  components: {
    ParticipantObjectiveSingle,
    Loading,
  },
  props: {
    participant: {
      type: Object,
      required: true,
    }
  },
  data: function() {
    return {
      evidenceDialog: false,
      image: null,
      memo: null,
      success: null,
      imageError: false,
      errorMessages: null,
      submitting: false,
      requestedObjectives: [],
      imagePreview: null,
      imageLabel: null,
      memoRules: [
        v => (!v || v.length <= 4000) || 'Please restrain yourself to 4,000 characters',
      ],
    }
  },
  computed: {
    ...mapState({
      userCoordinates: state => state.userlocation.coordinates,
    }),
    objectiveList() {
      return this.participant.participantobjective_set.filter(x => x.selectable == true)
    },
    evidenceInput() {
      return this.image || this.memo
    }
  },
  watch: {
    evidenceDialog: function(value) {
      // Fetch the user location when the dialog is opened.
      if (value) {
        this.$store.dispatch('userlocation/get')
      }
    }
  },
  methods: {
    imageInput: function(event) {
      let valid = checkImageValue(event.target.files[0])
      if (valid === true) {
        this.imageError = false
        this.image = event.target.files[0]
        this.imageLabel = `${this.image.name} (${formatBytes(this.image.size)})`
        this.imagePreview = new Image()
        this.imagePreview.src = window.URL.createObjectURL(this.image)
      } else {
        this.image = null
        this.imagePreview = null
        this.imageError = valid
      }
    },
    clearImage() {
      this.image = null
      this.imagePreview = null
      this.imageLabel = null
      this.imageError = false
    },
    closeDialog() {
      this.clearImage()
      this.evidenceDialog = false
    },
    submit () {
      console.log('submit')
      this.errorMessages = null
      if (!this.memo && !this.image ) {
        this.success = false
        this.errorMessages = ['Either an image or memo must be provided.']
      } else {
        this.success = null
        this.submitting = true
        this.actualSubmit()
      }
    },
    actualSubmit () {
      console.log('actual submit')
      const data = new FormData()
      if (this.memo) {
        data.append('memo', this.memo)
      }
      if (this.image) {
        data.append('image', this.image)
      }
      if (this.userCoordinates) {
        data.append('latitude', this.userCoordinates.lat)
        data.append('longitude', this.userCoordinates.lng)
      }
      for (let i = 0; i < this.requestedObjectives.length; i++) {
        data.append('requested_objectives', this.requestedObjectives[i]);
      }
      return allianceAPI.post(
        '/quest/participant/' + this.participant.id + '/evidence/create/',
        data,
        { timeout: imageTimeout },
      )
        .then((response) => {
          console.log('success!')
          console.log(response)
          this.success = true
          this.memo = null
          this.clearImage()
          this.requestedObjectives = []
          this.$store.dispatch('participants/getAll')
        })
        .catch((error) => {
          this.errorMessages = parseResponseErrors(error)
          this.success = false
        })
        .finally(() => this.submitting = false)
    },
  },
}
</script>
