<template>
  <span class="multiselect-list">
    <v-combobox
      v-model="selectedTeamList"
      :items="teamList"
      :disabled="disabled"
      :rules="rules"
      :search-input.sync="searchInput"
      item-text="name"
      item-value="name"
      :hint="hint"
      persistent-hint
      :label="label"
      chips
      multiple
      :menu-props="{closeOnContentClick: true}"
      @change="searchInput=''"
    >
      <template v-slot:selection="data">
        <v-chip
          v-bind="data.attrs"
          :input-value="data.selected"
          :disabled="data.disabled"
        >
          <span
            v-if="data.item.name"
            class="pr-2"
          >
            {{ data.item.name }}
          </span>
          <span
            v-else
            class="pr-2"
          >
            {{ data.item }}
          </span>
          <v-icon
            v-if="canDeselect(data.item)"
            small
            @click="data.parent.selectItem(data.item)"
          >
            $close
          </v-icon>
        </v-chip>
      </template>
    </v-combobox>
  </span>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    defaultTeamnameList: {
      type: Array,
      required: false,
      default: () => [],
    },
    label: {
      type: String,
      required: false,
      default: 'Teams',
    },
    hint: {
      type: String,
      required: false,
      default: '',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: function() {
    return {
      searchInput: '',
      selectedTeamList: [],
      rules: [
        v => !!v || 'Field is required',
        v => v.length <= 24 || 'Please restrain yourself to 24 recipients',
      ],
    }
  },
  computed: {
    ...mapState({
      teamList: state => state.teams.all,
    }),
  },
  watch: {
    selectedTeamList: function () {
      this.$emit('input', this.getSelectedTeamnames())
    },
  },
  created: function () {
    this.selectDefaultTeams()
  },
  methods: {
    canDeselect(item) {
      if (this.defaultTeamnameList.indexOf(item.name) >= 0) {
        return false
      }
      if (this.defaultTeamnameList.indexOf(item) >= 0) {
        return false
      }
      return true
    },
    selectDefaultTeams() {
      if (this.defaultTeamnameList) {
        for (let i = 0; i < this.defaultTeamnameList.length; i++) {
          let name = this.defaultTeamnameList[i]
          let team = this.$store.getters['teams/getByAttribute']({key: 'name', value: name})
          if (team && this.selectedTeamList.indexOf(team) < 0) {
            this.selectedTeamList.push(team)
          } else if (!team && this.selectedTeamList.indexOf(name) < 0) {
            this.selectedTeamList.push(name)
          }
        }
      }
    },
    getSelectedTeamnames() {
      const names = []
      for (let i = 0; i < this.selectedTeamList.length; i++) {
        const entry = this.selectedTeamList[i]
        if (typeof entry === 'string') {
          names.push(entry)
        } else {
          names.push(entry.name)
        }
      }
      return names
    },
  }
}
</script>
